import { Link } from 'react-router-dom';
import News from './sections/News'

export default function Main() {
    return (
        <div className="latest_news_page_wrapper">
            <section className="banner">
                <div className="banner_wrapper">
                    <p><Link to="/">Главная</Link> - Новости AVATAR </p>
                    <h3>ПОСЛЕДНИЕ<br/>НОВОСТИ</h3>
                </div>
            </section>
            <section className="latest_news_wrapper">
                <div className="latest_news_nav_wrapper">
                    <nav className="latest_news_nav">
                        <Link to="/news?art=1">Акции</Link>
                        <Link to="/news?art=2">Турниры</Link>
                        <Link to="/news?art=3">Новости</Link>
                        <Link to="/news?art=4">Статьи</Link>
                    </nav>
                </div>
                <News />
            </section>
        </div>
    )
}
