import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import block_1 from '../../img/cfgicons/monitor.png'
import block_2 from '../../img/cfgicons/videocard.png'
import block_3 from '../../img/cfgicons/processor.png'
import block_4 from '../../img/cfgicons/memory.png'
import block_5 from '../../img/cfgicons/mouse.png'
import block_6 from '../../img/cfgicons/keyboard.png'
import block_7 from '../../img/cfgicons/headpones.png'
import block_8 from '../../img/cfgicons/chair.png'

export default function Main() {
    return (
        <Widget />
    )
  }

  function Widget(){

  const [swipe, setSwipeType] = useState('ARENA');

  function changeWidget(event, type){
    let all_actives = document.querySelectorAll('.widget_wrapper .active');
    all_actives.forEach((el)=>{
      el.classList.remove('active');
    })
    event.target.classList.add('active');
    setSwipeType(type);
  }

  return (
    <section className="widget">
      <div class="widget_wrapper">
        <button class="active" onClick={(e) => {changeWidget(e, 'ARENA')}}>АРЕНА</button>
        <hr type={swipe} />
        <button onClick={(e) => {changeWidget(e, 'CLUB')}} >КЛУБ</button>
      </div>
        <Arena swipe={swipe} />
        <Club swipe={swipe} />
    </section>
  )

}

function Club(props){
  const [type, setBlockType] = useState('STANDART');
  const changeBlock = (event, type)=>{
    let all_actives = document.querySelectorAll('._club .active');
    all_actives.forEach((el)=>{
      el.classList.remove('active');
    })
    event.target.classList.add('active');
    setBlockType(type)
  }
  if(props.swipe=="CLUB"){
    return (
      <div>
        <div className="widget_btns _club">
          <button class="active" onClick={(e) => {changeBlock(e, 'STANDART')}}>STANDART(20 ПК)</button>
          <button onClick={(e) => {changeBlock(e, 'VIP')}} >VIP(10 ПК)</button>
          <button onClick={(e) => {changeBlock(e, 'BOOTCAMP')}} >BOOTCAMP(5 ПК)</button>
        </div>
        <ShowClubBlock type={type} />
      </div>
    )
    }
}

function Arena(props){
    const [type, setBlockType] = useState('STANDART');
    const changeBlock = (event, type)=>{
      let all_actives = document.querySelectorAll('._arena .active');
      all_actives.forEach((el)=>{
        el.classList.remove('active');
      })
      event.target.classList.add('active');
      setBlockType(type)
    }
    if(props.swipe=="ARENA"){
      return (
        <div>
          <div className="widget_btns _arena">
            <button class="active" onClick={(e) => {changeBlock(e, 'STANDART')}}>STANDART(44 ПК)</button>
            <button onClick={(e) => {changeBlock(e, 'VIP')}} >VIP(7 ПК)</button>
          </div>
          <ShowArenaBlock type={type} />
        </div>
      )
    }
  }

  const arena_standart_info = [
    { key: "Монитор", value: "Full HD 27″ 280 Гц", img: block_1 },
    { key: "Видеокарта", value: "RTX 3070", img : block_2 },
    { key: "Процессор", value: "Ryzen 5 5600", img : block_3 },
    { key: "Оперативная память", value: "16 GB", img : block_4 },
    { key: "Мышь", value: "HyperX Pulsefire Surge", img : block_5 },
    { key: "Клавиатура", value: "HyperX Alloy Origins Core", img : block_6 },
    { key: "Гарнитура", value: "HyperX Cloud Alpha S", img : block_7 },
    { key: "Кресло", value: "Knight Outrider LTD", img : block_8 },
  ]
  const arena_vip_info = [
    { key: "Монитор", value: "Full HD 24,5″ 360 Гц", img: block_1 },
    { key: "Видеокарта", value: "RTX 4070 Ti", img: block_2 },
    { key: "Процессор", value: "Ryzen 7 7800X3D", img: block_3 },
    { key: "Оперативная память", value: "32 GB", img: block_4 },
    { key: "Мышь", value: "HyperX Pulsefire Surge", img: block_5 },
    { key: "Клавиатура", value: "HyperX Alloy Origins Core", img: block_6 },
    { key: "Гарнитура", value: "HyperX Cloud Alpha S", img: block_7 },
    { key: "Кресло", value: "Zombie Predator", img: block_8 },
  ]

  const club_standart_info = [
    { key: "Монитор", value: "Full HD 27″ 280 Гц", img: block_1 },
    { key: "Видеокарта", value: "RTX 3050", img : block_2 },
    { key: "Процессор", value: "Ryzen 5 5500", img : block_3 },
    { key: "Оперативная память", value: "16 GB", img : block_4 },
    { key: "Мышь", value: "Razer Deathadder", img : block_5 },
    { key: "Клавиатура", value: "Razer Huntsman", img : block_6 },
    { key: "Гарнитура", value: "Razer BlackShark V2", img : block_7 },
    { key: "Кресло", value: "Knight N1", img : block_8 },
  ]
  const club_vip_info = [
    { key: "Монитор", value: "Full HD 27″ 280 Гц", img: block_1 },
    { key: "Видеокарта", value: "RTX 3060", img : block_2 },
    { key: "Процессор", value: "Ryzen 5 5600", img : block_3 },
    { key: "Оперативная память", value: "16 GB", img : block_4 },
    { key: "Мышь", value: "HyperX Pulsefire Surge", img : block_5 },
    { key: "Клавиатура", value: "HyperX Alloy Origins Core", img : block_6 },
    { key: "Гарнитура", value: "HyperX Cloud Alpha S", img : block_7 },
    { key: "Кресло", value: "Knight Outrider LTD", img : block_8 },
  ]
  const club_bootcamp_info = [
    { key: "Монитор", value: "Full HD 27″ 280 Гц", img: block_1 },
    { key: "Видеокарта", value: "RTX 3070", img : block_2 },
    { key: "Процессор", value: "Ryzen 5 7600X", img : block_3 },
    { key: "Оперативная память", value: "16 GB", img : block_4 },
    { key: "Мышь", value: "HyperX Pulsefire Surge", img : block_5 },
    { key: "Клавиатура", value: "HyperX Alloy Origins Core", img : block_6 },
    { key: "Гарнитура", value: "HyperX Cloud Alpha S", img : block_7 },
    { key: "Кресло", value: "Knight Outrider LTD", img : block_8 },
  ]

  function BlocksArenaFor(props){

    return props.info.map((el)=>{
      return (
        <div class="arena_standart_single">
          <img src={el.img} />
          <div class="arena_standart_single_text">
            <p>{ el.key }</p>
            <span>{ el.value }</span>
          </div>
        </div>
      )
    })
  }

  function ShowArenaBlock(props){
    if(props.type=="STANDART"){
      return (
        <div class="arena_blocks_wrapper">
          <BlocksArenaFor info={arena_standart_info}  />
        </div>
      )
    }
    if(props.type=="VIP"){
      return (
        <div class="arena_blocks_wrapper">
          <BlocksArenaFor info={arena_vip_info}  />
        </div>
      )
    }
  }

  function ShowClubBlock(props){
    if(props.type=="STANDART"){
      return (
        <div class="arena_blocks_wrapper">
          <BlocksArenaFor info={club_standart_info}  />
        </div>
      )
    }
    if(props.type=="VIP"){
      return (
        <div class="arena_blocks_wrapper">
          <BlocksArenaFor info={club_vip_info}  />
        </div>
      )
    }
    if(props.type=="BOOTCAMP"){
      return (
        <div class="arena_blocks_wrapper">
          <BlocksArenaFor info={club_bootcamp_info}  />
        </div>
      )
    }
  }
