import React from 'react'
import Arena from './sections/Arena'
import Comp from './sections/Comp'
import Game from './sections/Games'
import { Link } from 'react-router-dom';
import about from '../img/about.jpg'
import pin_1 from '../img/pin-1.png'
import pin_2 from '../img/pin-2.png'
import pin_3 from '../img/pin-3.png'
import decor_1 from '../img/dash_tenth.png'
import decor_3 from '../img/dash_eleventh.png'

export default function About() {
    return (
        <div className="about_page_wrapper">
            <section className="banner">
                <div className="banner_wrapper">
                    <p><Link to="/">Главная</Link> - О компании AVATAR </p>
                    <h3>О НАС</h3>
                </div>
            </section>
            <section className="about_page_info">
                <img className="decor" src={decor_1} />
                <img className="decor" src={decor_3} />
                <div className="about_wrapper">
                    <img  src={about}/>
                    <div className="about_content">
                        <div className="about_text">
                        <p>Самая большая киберспортивная АРЕНА
                            в Бресте, работающая 24/7, в которой слово
                            «сервис» стоит на первом месте! Мы хотим, чтобы
                            вы были для нас не просто гостем, а частью AVATAR
                            Community. Для этого мы создали команду, которая
                            в первую очередь заботится о Вас, старается учесть
                            все ваши предпочтения и пожелания.
                                <ul>
                                <li>Просторные VIP комнаты;</li>
                                <li>Комфортная lounge-зона с PS5 и бар;</li>
                                <li>Еженедельные розыгрыши;</li>
                                <li>Возможность стать частью киберспортивной команды.</li>
                                </ul>
                            </p>
                        </div>
                        <div className="about_link_wrap">
                        <Link className="about_link" to="/rules">ПРАВИЛА ПОСЕЩЕНИЯ</Link>
                        </div>
                    </div>
                </div>
                <div className="why_wrapper">
                    <div className="why_block">
                        <img src={pin_1} />
                        <p>Большая киберспортивная арена в Бресте! 300 квадратных метров киберспортивного пространства!</p>
                    </div>
                    <div className="why_block">
                        <img src={pin_2} />
                        <p>Более 80 мощнейших игровых компьютеров: 3060, 3070 и даже 4070TI!</p>
                    </div>
                    <div className="why_block">
                        <img src={pin_3} />
                        <p>Еженедельные киберспортивные турниры с большим призовым фондом!</p>
                    </div>
                </div>
            </section>
            <Arena/>
            <section className="bar_2"></section>
            <Comp/>
            <Game/>
        </div>
    )
}
