import React from 'react'
import logo from '../img/logo_com.png'
import vk from '../img/vk.png'
import tt from '../img/tt.png'
import tg from '../img/tg.png'
import inst from '../img/in.png'
import tb from '../img/tb.png'
import ds from '../img/ds.png'
import pincall from '../img/pin-call.png'
import pintg from '../img/pin-telega.png'
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer>
      <div className="footer_wrapper">
        <div className="social">
          <img src={logo} className="logo" />
          <div className="socials">
            <a href="https://vk.com/avatarhub"><img src={vk}/></a>
            <a href="#"><img src={ds}/></a>
            <a href="https://www.tiktok.com/@avatararenabrest?_t=8oaePRTIC73&_r=1"><img src={tt}/></a>
            <a href="https://t.me/+vqv0kOGIExM5YWIy"><img src={tg}/></a>
            <a href="https://t.me/AVTournamentsBot"><img src={tb}/></a>
            <a href="https://www.instagram.com/avatar.arena.brest/"><img src={inst}/></a>
          </div>
          <a className="mail" href="mailto:avatar.brest@gmail.com">avatar.brest@gmail.com</a>
        </div>
        <div className="links">
          <Link to="/arena">АРЕНА</Link>
          <Link to="/price">ЦЕНЫ</Link>
          <Link to="/news">НОВОСТИ</Link>
          <Link to="/about">О НАС</Link>
        </div>
        <div className="links">
          <Link to="/club">КЛУБ</Link>
          <Link to="/games">ИГРЫ</Link>
          <Link to="/contacts">ЗАБРОНИРОВАТЬ</Link>
          <Link to="/rules">ПРАВИЛА КЛУБА</Link>
        </div>
        <div className="address">
          <h5>АРЕНА: +375 (29) 548 55 55</h5>
          <div class="address_text">
            <p>г. Брест, ул. Советская, 71/2</p>
            <a href="tel:+375295485555"><img src={pincall} /></a>
            <a href="https://t.me/avatararenabrest"><img src={pintg} /></a>
          </div>
          <h5>КЛУБ: +375 (29) 222 22 14</h5>
          <div className="address_text">
            <p>г. Брест, ул. Пушкинская, 23</p>
            <a href="tel:+375292222214"><img src={pincall} /></a>
            <a href="https://t.me/avatar_cyberclub"><img src={pintg} /></a>
          </div>
        </div>
      </div>
      <div className="all_rights">
        2024 AVATAR - Компьютерный клуб. Все права защищены &copy;
      </div>
    </footer>
  )
}
