import React, {useEffect, useState} from 'react'
import { Buffer } from 'buffer';
import { Link } from 'react-router-dom';
import axios from "axios"

axios.defaults.baseURL = "http://localhost:8080";

import loading_gif from '../../img/loading.gif'

export default function Main() {
    const [post, setPost] = useState({});
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        // выяснить, как получить id из url (после /)
        const fetchData = async () => {
            let path = window.location.pathname.split('/');
            let id = path[path.length-1];
            try {
                const response = await axios.get('/api/post/'+id);
                setPost(response.data);
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
            }
        };
      fetchData();
    }, []);


    if (loading) return (
        <section class="single_news">
            <img class="loading" src={loading_gif}/>
        </section>
    )

    return (
        <section class="single_news">
            <div class="single_news_wrap">
                <div class="snw_preview">
                    <img src={`data:image/jpeg;base64, ${Buffer.from(post.cover).toString('base64')}`} />
                    <div class="snw_title">
                        <h3>{post.title}</h3>
                        <span>{new Date(post.currenttimestamp).toLocaleString("en-GB", {
                            day: "numeric",
                            month: "numeric",
                            year: "numeric"
                        })}</span>
                    </div>
                </div>
                <div class="snw_content">
                    { post.content }
                </div>
            </div>
        </section>
    )
}
