import React, { useEffect, useState, useRef} from 'react';
import axios from 'axios'

import vid from '../img/vid_club.mp4';

const types = ["ARENA","CYBERCLUB"]
const api_login = "stas";
const api_pass = "6667";

let token = ''

export default function Main() {
    const videoRef = useRef(null);

    const handlePlay = () => {
        if (videoRef.current) {
        videoRef.current.play();
        }
    };

    useEffect(() => {
        let userId = sessionStorage.getItem('userId')
        if(userId){
            window.location.href = '/my';
        }
      }, []);
    return (
        <div className="auth_page_wrapper">
        <video className="auth_video"
            autoplay=""
            muted={true}
            loop
            playsinline=""
            onLoadedMetadata={handlePlay}
            src={vid}>
        </video>
        <div className="auth_video_overlay" />
        <div className="auth_content">
                <SwipeAuth />
            </div>
        </div>
    )
}

function SwipeAuth(){
    const [auth_type, setAuthType] = useState('login');

    const changeAuth = (event, type)=>{
      let all_actives = document.querySelectorAll('.auth_header button');
      all_actives.forEach((el)=>{
        el.classList.remove('active');
      })
      event.target.classList.add('active');
      setAuthType(type)
    }
    return (
      <div class="auth_body">
        <div className="auth_header">
          <button onClick={(e) => {changeAuth(e, 'login')}} className="active">Войти</button>
          <button onClick={(e) => {changeAuth(e, 'register')}}>Регистрация</button>
        </div>
        <div class="auth_form">
            <LoginWindow authtype={auth_type} />
            <RegWindow authtype={auth_type} />
        </div>
      </div>
    )
  }

function LoginWindow(props){

    if(props.authtype=="login"){
        const [login, setLogin] = useState('');
        const [pass, setPass] = useState('');
        function handleLogin(value){
            setLogin(value)
        }
        function handlePass(value){
            setPass(value)
        }
        async function AuthLogin(){
            if(!login || !pass){
                showMessage(false, "Введите данные")
            }
            if(login && pass){
                let option = document.querySelector('.auth_type');
                if(option.value == types[0]){
                    axios.defaults.baseURL = process.env.REACT_APP_GIZMO_ARENA_API_URL;
                    sessionStorage.setItem('api_adress', types[0])
                }
                if(option.value == types[1]){
                    axios.defaults.baseURL = process.env.REACT_APP_GIZMO_CLUB_API_URL;
                    sessionStorage.setItem('api_adress', types[1])
                }
                try {
                    await axios({
                        url: `/api/user/v2.0/auth/accesstoken?Username=${login}&Password=${pass}`,
                        method: 'get',
                    }).then(()=>{
                        loginIn(option.value).then((res)=>{
                            getUserIdByLogin(login);
                        })
                    })
                } catch(e){
                    showMessage(false, "Пользователь не найден")
                }
            }
        }
        return (
            <div class="login_wrapper">
                <label>Выберите клуб:</label>
                <select
                class="auth_type">
                    <option selected>{ types[0] }</option>
                    <option>{ types[1] }</option>
                </select>
                <input type="text" value={login} onChange={el => handleLogin(el.target.value)} placeholder='Логин'></input>
                <input  type="password" value={pass} onChange={el => handlePass(el.target.value)} placeholder='Пароль'></input>
                <button onClick={AuthLogin} class="auth_submit">Войти</button>
            </div>
      )
    }
  }

  function RegWindow(props){
    if(props.authtype=="register"){
        const [login, setLogin] = useState('');
        const [pass, setPass] = useState('');
        const [phone, setPhone] = useState('');
        const [accept, setAccept] = useState('');
        function handleLogin(value){
            setLogin(value)
        }
        function handlePhone(value){
            setPhone(value)
        }
        function handlePass(value){
            setPass(value)
        }
        function handleAccept(value){
            setAccept(value)
        }
        async function AuthReg(){
            if(!login || !pass || !phone){
                showMessage(false, "Введите данные")
            } else {
                if(!accept){
                    showMessage(false, "Требуется соглашение")
                }
            }
            if(login && pass && phone && accept){
                let option = document.querySelector('.auth_type');
                if(option.value == types[0]){
                    axios.defaults.baseURL = process.env.REACT_APP_GIZMO_ARENA_API_URL;
                    sessionStorage.setItem('api_adress', types[0])
                }
                if(option.value == types[1]){
                    axios.defaults.baseURL = process.env.REACT_APP_GIZMO_CLUB_API_URL;
                    sessionStorage.setItem('api_adress', types[1])
                }
                try {
                    loginIn(option.value).then((res)=>{
                        axios({
                            url: `/api/v2.0/users`,
                            method: 'post',
                        data: {
                            "password": pass,
                            "username": login,
                            "userGroupId": 2,
                            "isNegativeBalanceAllowed": true,
                            "isPersonalInfoRequested": true,
                            "mobilePhone": phone
                        },
                        headers: {
                            'accept': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                        }).then((res)=>{
                            sessionStorage.setItem('userId',res.data.result.id);
                            sessionStorage.setItem('token',token);
                            window.location.href = '/my';
                        })
                    })
                } catch(e){
                    showMessage(false, "Неверные данные")
                }
            }
        }
      return (
        <div class="reg_wrapper">
            <label>Выберите клуб:</label>
            <select class="auth_type">
                <option selected>{ types[0] }</option>
                <option>{ types[1] }</option>
            </select>
            <label>Придумайте уникальный логин:</label>
            <input type="text" value={login} onChange={el => handleLogin(el.target.value)} placeholder='Логин'></input>
            <input type="phone" value={phone} onChange={el => handlePhone(el.target.value)} placeholder='+375 __ ___-__-__'></input>
            <input  type="password" value={pass} onChange={el => handlePass(el.target.value)} placeholder='Пароль'></input>
            <div class="auth_input_wrapper">
                <label>Согласен с <a target="_blank" href="https://avatararena.by/rules">правилами</a> обработки персональных данных:</label>
                <input type="checkbox" onChange={el => handleAccept(el.target.checked)}></input>
            </div>
            <button  onClick={AuthReg} class="auth_submit">Создать</button>
        </div>
      )
    }
  }

  function showMessage(type, text){
    let auth_page = document.querySelector('.auth_page_wrapper');
    let message = document.createElement('div');
    type ? message.className = 'auth_success' : message.className = 'auth_error';
    message.innerText = text
    auth_page.appendChild(message)
    setTimeout(()=>{
        auth_page.removeChild(message)
    },2000)
  }

  async function getUserIdByLogin(login){
    try {
        await axios({
            url: `/api/users/${login}/userid`,
            method: 'get',
            headers:{
                'accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then((res)=>{
            showMessage(true, "Авторизация выполнена")
            sessionStorage.setItem('userId',res.data.result)
            sessionStorage.setItem('token',token);
            window.location.href = '/my';
        })
    } catch (e) {
        showMessage(false, "Пользователь не найден")
    }
  }

  async function loginIn(type){
    if(type==types[0]){
        try {
            await axios({
                url: `${process.env.REACT_APP_GIZMO_ARENA_API_URL}/auth/token?username=${api_login}&password=${api_pass}`,
                method: 'get',
                headers: {
                    'accept': 'application/json'
                }
            }).then((res)=>{ if(res.data.token){
                token = res.data.token
            } })
        } catch(e) {
            showMessage(false, "Не удалось установить соединение")
        }
    }
    if(type==types[1]){
        try {
            await axios({
                url: `${process.env.REACT_APP_GIZMO_CLUB_API_URL}/auth/token?username=${api_login}&password=${api_pass}`,
                method: 'get',
            }).then((res)=>{ if(res.data.token){
                token = res.data.token
            } })
        } catch(e) {
            showMessage(false, "Не удалось установить соединение")
        }
    }
  }
  