import React from 'react'
import { Link } from 'react-router-dom';
import game_1 from '../../img/game_cs2.jpg'
import game_2 from '../../img/game_gta5.jpg'
import game_3 from '../../img/game_hell.jpg'
import game_4 from '../../img/game_forza.jpg'
import game_5 from '../../img/link_to_ul.jpg'

export default function Main() {
    return (
        <section className="game">
          <h3>ИГРЫ И ОБОРУДОВАНИЕ</h3>
          <div className="game_text">Только лицензионные игры с возможностью подключения вашего аккаунта
          и участия в турнирах на нашей Арене</div>
          <div className="game_slider">
            <img src={game_1} />
            <img src={game_2} />
            <img src={game_3} />
            <img src={game_4} />
            <Link to="/games">
              <img src={game_5} />
            </Link>
          </div>
        </section>
    )
}
