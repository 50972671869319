import React, {useEffect, useState} from 'react'
import { Buffer } from 'buffer';
import { Link } from 'react-router-dom';
import axios from "axios"

axios.defaults.baseURL = process.env.REACT_BASE_API_URL;

import loading_gif from '../../img/loading.gif'
import decor from '../../img/dash_first.png'
import decor_3 from '../../img/dash_eleventh.png'

export default function Main() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/posts');
        setPosts(response.data);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

    if (loading) return (
      <section className="news">
        <img className="decor" src={decor} />
        <img className="decor" src={decor} />
        <img className="decor" src={decor_3} />
          <h3>ПОСЛЕДНИЕ НОВОСТИ</h3>
          <div className="news_wrap">
            <img class="loading" src={loading_gif}/>
          </div>
        </section>
    )
    return (
        <section className="news">
        <img className="decor" src={decor} />
        <img className="decor" src={decor} />
        <img className="decor" src={decor_3} />
          <h3>ПОСЛЕДНИЕ НОВОСТИ</h3>
          <div className="news_wrap">
            {posts.map((item, index) => (
                <div className="news_block">
                <Link className="news_link_wrap" to={"/news/"+item.id}>
                  <img src={`data:image/jpeg;base64, ${Buffer.from(item.cover).toString('base64')}`} />
                </Link>
                <div className="news_block_content">
                  <h5>{item.title}</h5>
                  <p>{item.content}</p>
                  <span>{new Date(item.currenttimestamp).toLocaleString("en-GB", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric"
                  })}</span>
                </div>
              </div>
            ))}
          </div>
          <Link className="link_to_news" to="/news">ВСЕ НОВОСТИ</Link>
        </section>
    )
}
