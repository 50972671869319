import { Link } from 'react-router-dom';

export default function Rules() {
    return (
        <div className="rules_page_wrapper">
            <section className="banner">
                <div className="banner_wrapper">
                    <p><Link to="/">Главная</Link> - Правила клубов AVATAR </p>
                    <h3>ПРАВИЛА КЛУБА</h3>
    </div>
        </section>
        <p className="rules_text">
            Клуб оставляет за собой право в ограничении доступа в соответствии с требованиями служб
            равопорядка и органов милиции, прописанными в законе о местах массового пребывания людей. Посетители и их личные вещи могут 
            быть досмотрены администратором клуба с целью обеспечения общей безопасности. 
            В случае отказа пройти данную процедуру администратор в праве не допустить посетителя в компьютерный клуб:
                    <ul>
                    <li>Запрещается подключение девайсов Bloody;</li>
                    <li>Запрещается проносить алкогольные напитки в клуб;</li>
                    <li>Запрещается приносить с собой еду и напитки, приобретённые вне клуба;</li>
                    <li>Запрещается спать в зале;</li>
                    <li>Запрещён доступ в зал с животными;</li>
                    <li>Запрещается курение на всей территории клуба;</li>
                    <li>Запрещается пользоваться симуляторами интернет-казино;</li>
                    <li>Запрещается употреблять на территории зала снюс, насвай и другие виды бездымного табака;</li>
                    <li>Запрещается находиться за компьютером в верхней одежде, вешать её на спинки кресел, 
                        находиться в зале с большими сумками, без обуви: верхнюю одежду можно оставить в гардеробе 
                        либо на вешалках, а сумку поставить в камеру хранения;</li>
                    <li>Запрещается класть ноги на стол;</li>
                    <li>Запрещается наносить вред имуществу клуба или стучать по нему;</li>
                    <li>Запрещается трогать системные блоки, подключать к ним зарядные устройства;</li>
                    <li>Запрещается любая несанкционированная торговля на территории клуба;</li>
                    <li>Продление игрового времени на ПК свыше того, которое вы приобрели, возможно только после уточнения у администратора;</li>
                    <li>Посещение клуба в ночное время возможно ТОЛЬКО с 18-ти лет при предъявлении оригинала 
                        паспорта/водительского удостоверения/военного билета;</li>
                    <li>Фото- и видеосъёмка возможны только с использованием непрофессиональной техники;</li>
                    <li>Профессиональная фото- и видеосъёмка возможны только с разрешения администрации клуба;</li>
                    <li>Посещая клуб, вы соглашаетесь принимать участие в возможной фото- и видеосъёмке и разрешаете 
                        клубу использовать фото, видео и аудиозаписи с вашим участием в коммерческих целях на территории всех стран мира;</li>
                    <li>Вам придётся покинуть клуб, если вы будете себя агрессивно вести по отношению к другим гостям и (или) персоналу, 
                        а также нарушать правила клуба;</li>
                    <li>Запрещается ненормативная лексика и крики в клубе;</li>
                    <li>Администрация не несёт ответственность за сохранность личных вещей и ценностей в помещениях клуба;</li>
                    <li>Оплачивая игровое время, вы автоматически соглашаетесь с изложенными перед вами правилами;</li>
                    <li>Администратор может отказать вам в обслуживании не объясняя причин;</li>
                    <li>Администратор не несёт ответственность за сохранность игровых аккаунтов посетителей;</li>
                    <li>Запрещён доступ в зал лицам в состоянии алкогольного или наркотического опьянения, степень
                        которого администратор клуба определяет по своему усмотрению;</li>
                    <li>Вам может быть отказано в посещении клуба при наличии неприятного, либо резкого запаха, который 
                        может помешать другим посетителям;</li>

                    <li>В случае причинения ущерба имуществу клуба по вине посетителя, посетитель обязан в полном объёме 
                        возместить сумму причинённого ущерба. Стоимость имущества для расчёта суммы ущерба определяется клубом;</li>
                    </ul>
                    При нарушении настоящих Правил, сотрудники клуба имеют право вывести такого посетителя из компьютерного клуба без 
                    компенсации стоимости услуг.
        </p>
    </div>
    )
}
