import game_1 from '../img/game_cs2_secondv.jpg'
import game_2 from '../img/game_dota2.jpg'
import game_3 from '../img/game_pubg.jpg'
import game_4 from '../img/game_fort.jpg'
import game_5 from '../img/game_valorant.jpg'
import game_6 from '../img/game_gta5.jpg'
import game_7 from '../img/game_cod.jpg'
import game_8 from '../img/game_rust.jpg'
import game_9 from '../img/game_wow.jpg'
import game_10 from '../img/game_hell.jpg'
import decor from '../img/dash_eleventh.png'
import { Link } from 'react-router-dom';

export default function Games() {
    return (
        <div className="games_page_wrapper">
            <section className="banner">
                <div className="banner_wrapper">
                    <p><Link to="/">Главная</Link> - Игры в AVATAR </p>
                    <h3>ИГРЫ</h3>
                </div>
            </section>
        <section className="games_list">
            <img className="decor" src={decor} />
            <img className="decor" src={decor} />
            <p>Только лицензионные игры с возможностью подключения вашего аккаунта</p>
            <div className="games_list_wrapper">
                <img src={game_1} />
                <img src={game_2} />
                <img src={game_3} />
                <img src={game_4} />
                <img src={game_5} />
                <img src={game_6} />
                <img src={game_7} />
                <img src={game_8} />
                <img src={game_9} />
                <img src={game_10} />
            </div>
        </section>
        </div>
    )
}
