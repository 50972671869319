import React from 'react'
import { Link } from 'react-router-dom';

export default function Main() {
    return (
        <section className="comp">
          <h3>КОМПЬЮТЕРНЫЙ КЛУБ</h3>
          <div className="comp_block">
            <div className="comp_wrapper">
              <div className="comp_content">
                <h5>КЛУБ</h5>
                <p>Первый клуб в Бресте, который уже успел зарекомендовать
                себя и покорить сердца наших постоянных гостей.</p>
                <Link to="/club">ПОДРОБНЕЕ</Link>
              </div>
              <div className="comp_address">
                <a href="tel:+375292222214">+375292222214</a>
                <p>Брест, ул. Пушкинская, 23</p>
              </div>
            </div>
          </div>
        </section>
    )
}
