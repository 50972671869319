import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import SimpleImageSlider from "react-simple-image-slider";
import axios from 'axios';

import loading_gif from '../img/loading.gif';
import avatar from '../img/avatar.jpg';
import edit from '../img/edit.png';
import refresh from '../img/refresh.png';
import log from '../img/log.png';
import slide1 from '../img/slide_1.jpg';
import slide2 from '../img/slide_subs.jpg';
import slide3 from '../img/slide_2.jpg';
import slide4 from '../img/slide_tournament.jpg';

const types = ["ARENA", "CYBERCLUB"];
const personalAccountData = [
    { key: 'STANDARD', number: 2 },
    { key: 'С аккаунтом клуба', number: 3 },
    { key: 'Старожила', number: 6 },
    { key: 'AVATAR+', number: 9 },
    { key: 'AVATAR PRO', number: 10 },
    { key: 'AVATAR BASIC', number: 11 }
];
const add = [slide4, slide1, slide2, slide3];

export default function Main() {
    const [loading, setLoading] = useState(true);
    const [pockets, setPockets] = useState([]);
    const [userGroupId, setUserGroupId] = useState(null);
    const [username, setUsername] = useState('');
    const [createdAccTime, setCreatedAccTime] = useState('');
    const [userBalance, setUserBalance] = useState(0);
    const [userAVCoins, setUserAVCoins] = useState(0);
    const [isEditing, setIsEditing] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [avatarSrc, setAvatarSrc] = useState(avatar);

    const handleEditClick = () => {
        setIsEditing(!isEditing);
    };

    const handleSave = async () => {
        const userId = sessionStorage.getItem('userId');
        const token = sessionStorage.getItem('token');

        const data = { UserId: userId };

        if (username !== '') {
            data.Username = username;
        }
        if (phoneNumber !== '') {
            data.MobilePhone = phoneNumber;
        }

        try {
            await axios.post(`/api/users/`, null, {
                params: data,
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
        } catch (error) {
            console.error('Error updating user data:', error);
            alert('Ошибка при обновлении данных. Пожалуйста, попробуйте еще раз.');
        } finally {
            setIsEditing(false);
        }
    };

    const handleAvatarChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                let base64String = reader.result;

                if (base64String.startsWith('data:image/')) {
                    base64String = base64String.split(',')[1]; 
                }

                const userId = sessionStorage.getItem('userId');
                const token = sessionStorage.getItem('token');

                try {
                    await axios.put(`/api/v2.0/users/${userId}/picture`, {
                        picture: base64String
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    });

                    alert('Avatar successfully updated!');

                    const avatarResponse = await axios.get(`/api/v2.0/users/${userId}/picture`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });

                    console.log('Updated Avatar Response:', avatarResponse.data);

                    const updatedPicture = avatarResponse.data.result?.picture;
                    if (updatedPicture) {
                        setAvatarSrc(`data:image/png;base64,${updatedPicture}`);
                    } else {
                        console.warn('No updated picture found, using default image.');
                        setAvatarSrc(avatar);
                    }
                } catch (error) {
                    console.error('Error updating avatar:', error);
                    alert(`Error updating avatar: ${error.message}`);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        const userId = sessionStorage.getItem('userId');
        const token = sessionStorage.getItem('token');
        const address = sessionStorage.getItem('api_adress');

        if (!userId || !token) {
            window.location.href = '/auth';
            return;
        }

        if (address === types[0]) {
            axios.defaults.baseURL = process.env.REACT_APP_GIZMO_ARENA_API_URL;
        } else if (address === types[1]) {
            axios.defaults.baseURL = process.env.REACT_APP_GIZMO_CLUB_API_URL;
        }

        const fetchUserData = async () => {
            try {
                const userResponse = await axios.get(`/api/users/${userId}`, {
                    headers: {
                        'accept': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });

                setUserGroupId(userResponse.data.result.userGroupId);
                setUsername(userResponse.data.result.username);
                setCreatedAccTime(userResponse.data.result.createdTime);
                setPhoneNumber(userResponse.data.result.mobilePhone);

                const balanceResponse = await axios.get(`/api/users/${userId}/balance`, {
                    headers: {
                        'accept': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });

                setUserBalance(balanceResponse.data.result.deposits);
                setUserAVCoins(balanceResponse.data.result.points);

                const productTimeResponse = await axios.get(`/api/users/${userId}/producttime`, {
                    headers: {
                        'accept': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });

                const pockets_ = productTimeResponse.data.result.filter(item =>
                    item.totalSeconds !== item.usedSeconds && !item.isExpired && !item.isDeleted && !item.isDepleted
                );

                setPockets(pockets_);

                const response = await axios.get(`/api/v2.0/users/${userId}/picture`, {
                    headers: {
                        'accept': 'application/json',
                        'Authorization': `Bearer ${token}` 
                    }
                });

                const picture = response.data.result?.picture;
                if (picture) {
                    setAvatarSrc(`data:image/png;base64,${picture}`);
                } else {
                    console.warn('No picture found, using default image.');
                    setAvatarSrc(avatar);
                }

            } catch (error) {
                console.error(error);
                if (error.response && error.response.status === 401) {
                    sessionStorage.setItem('userId', '')
                    sessionStorage.setItem('token', '')
                    sessionStorage.setItem('api_adress', '')
                    alert('Ваша сессия истекла. Пожалуйста, войдите снова.');
                    window.location.href = '/auth';
                }
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, []);

    if (loading) return (
        <section className="my_account">
            <img className="loading" src={loading_gif} alt="Loading..." />
        </section>
    );

    const accountStatus = personalAccountData.find(item => item.number === userGroupId)?.key || 'Неизвестный статус';

    return (
        <div className="my_account">
            <div className="my_body_wrapper">
                <img className="log_out_icon" onClick={logOut} src={log} />
                <div className="my_body">
                    <div className="my_data">
                        <div className="my_block">
                            <img className="my_avatar" src={avatarSrc} alt="Avatar" />
                            {isEditing ? (
                                <div>
                                    <input
                                        type="text"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        placeholder="Введите имя"
                                    />
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleAvatarChange}
                                    />
                                </div>
                            ) : (
                                <div>
                                    <h4 className="my_name_is">{username}</h4>
                                </div>
                            )}
                        </div>
                        <div className="my_block">
                            <label>ВАШИ ДАННЫЕ:</label>
                            {isEditing ? (
                                <div>
                                    <input
                                        type="tel"
                                        value={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        placeholder="Введите номер телефона"
                                    />
                                    <button onClick={handleSave}>Сохранить</button>
                                </div>
                            ) : (
                                <div>
                                    <PhoneNumberDisplay phoneNumber={phoneNumber} />
                                    <button onClick={handleEditClick}>
                                        <img src={edit} alt="Edit" />
                                    </button>
                                </div>
                            )}
                            <p>ID: {sessionStorage.getItem('userId')}</p>
                        </div>
                    </div>
                    <div className="my_info">
                        <div className="my_block">
                            <p>Текущий статус: {accountStatus}</p>
                            <AccountRegistration registrationTime={createdAccTime} />
                        </div>
                        <div className="my_block balance">
                            <label>ОСНОВНОЙ СЧЕТ:</label>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div className="my_balance">{userBalance} BYN</div>
                                <div className="my_coins_balance">{userAVCoins} AVATAR Коинов</div>
                            </div>
                            <button><img src={refresh} alt="Refresh" /></button>
                        </div>
                    </div>
                </div>
                <div className="my_all">
                    <div className="my_pockets">
                        <div className="my_pocket_wrapper">
                            <h5>ДОСТУПНЫЕ ПАКЕТЫ</h5>
                            <h5>ОСТАЛОСЬ ВРЕМЕНИ</h5>
                        </div>
                        {pockets.length ?
                            '' :
                            <div className="my_pockets_empty">Нет доступных пакетов</div>
                        }
                        <div className="my_pocket_wrapper">
                            <div className="my_pocket_table">
                                {pockets.map((item) => (
                                    <p key={item.productName}>{item.productName.replace('*', '')}</p>
                                ))}
                            </div>
                            <div className="my_pocket_table">
                                {pockets.map((item) => (
                                    <TimeFormatter key={item.productName} seconds={item.totalSeconds - item.usedSeconds} />
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="my_add">
                        <SimpleImageSlider
                            images={add}
                            showBullets={true}
                            showNavs={true}
                            autoPlay={true}
                            autoPlayDelay={3.0}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

const formatSeconds = (totalSeconds) => {
    const date = new Date(0);
    date.setSeconds(totalSeconds);

    const hours = date.getUTCHours();
    const mins = date.getUTCMinutes();
    const formattedHours = hours > 0 ? `${hours} ч ` : '';

    return `${formattedHours}${mins} мин`;
};

const logOut = () => {
    sessionStorage.setItem('userId', '')
    sessionStorage.setItem('token', '')
    sessionStorage.setItem('api_adress', '')
    window.location.href = '/auth';
};

const TimeFormatter = ({ seconds }) => {
    return (
        <p>{formatSeconds(seconds)}</p>
    );
};

const AccountRegistration = ({ registrationTime }) => {
    const yearsAndDaysSinceRegistration = (registrationTime) => {
        const registeredDate = new Date(registrationTime);
        const now = new Date();

        const timeDifference = now - registeredDate;
        const totalDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

        const years = Math.floor(totalDays / 365);
        const days = totalDays % 365;

        return { years, days };
    };

    const { years, days } = yearsAndDaysSinceRegistration(registrationTime);

    return (
        <div>
            <p>Аккаунт зарегистрирован: {years > 0 ? `${years} год${years > 1 ? 'а' : ''} ` : ''}{days} дн. назад</p>
        </div>
    );
};

const PhoneNumberDisplay = ({ phoneNumber }) => {
    const formatPhoneNumber = (number) => {
        if (!number) {
            return 'неуказан';
        }

        const cleanedNumber = number.replace(/\D/g, '');

        let formattedNumber;
        if (cleanedNumber.startsWith('375')) {
            formattedNumber = cleanedNumber;
        } else if (cleanedNumber.startsWith('+')) {
            formattedNumber = '375' + cleanedNumber.slice(1);
        } else {
            formattedNumber = '375' + cleanedNumber;
        }

        if (formattedNumber.length === 12 || formattedNumber.length === 13) {
            return `+${formattedNumber}`;
        } else {
            return 'неправильный формат';
        }
    };
    return (
        <div>
            <p>Телефон: {formatPhoneNumber(phoneNumber)}</p>
        </div>
    );
};
