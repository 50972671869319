import React, { useEffect, useState } from 'react';
import logo from '../img/logo_com.png'
import time from '../img/dash_worktime.png'
import menu from '../img/menu.png'
import { Link } from 'react-router-dom';

export default function Header() {

    const [userId, setUserId] = useState('');
    useEffect(() => {
        let user_id = sessionStorage.getItem('userId')
        if(user_id){
            setUserId(user_id)
        }
    }, []);
    return (
        <header>
            <div className="header_wrapper">
                <div className="logo">
                    <Link to="/">
                        <img src={logo} />
                    </Link>
                </div>
                <nav>
                    <Link to="/arena">Арена</Link>
                    <Link to="/club">Клуб</Link>
                    <Link to="/price">Цены</Link>
                    <Link to="/games">Игры</Link>
                    <Link to="/news">Новости</Link>
                    <Link to="/contacts">Забронировать</Link>
                    <Link to="/tournament">Турниры</Link>
                    {userId &&
                    <Link to="/my">Кабинет</Link>
                    }
                    {!userId &&
                    <Link to="/auth">Войти</Link>
                    }
                </nav>
                <img className="time" src={time} />
                <div className="burger">
                    <img onClick={toggleMenu} src={menu} />
                </div>
            </div>
            <div className='presentation'></div>
            <div className='mobile_burger' onClick={handleClick}>
                <img onClick={closeMenu} src={menu} />
                <nav>
                    <Link to="/arena">Арена</Link>
                    <Link to="/club">Клуб</Link>
                    <Link to="/price">Цены</Link>
                    <Link to="/games">Игры</Link>
                    <Link to="/news">Новости</Link>
                    <Link to="/contacts">Забронировать</Link>
                    <Link to="/tournament">Турниры</Link>
                    {userId &&
                    <Link to="/my">Кабинет</Link>
                    }
                    {!userId &&
                    <Link to="/auth">Войти</Link>
                    }
                </nav>
            </div>
        </header>
    )
}

function toggleMenu(e){
    e.stopPropagation();
    let mobile_burger = document.querySelector('.mobile_burger')
    mobile_burger.classList.add('open')
    let body = document.querySelector('body')
    body.addEventListener('click',(e)=>{
        e.stopPropagation();
        mobile_burger.classList.remove('open')
    })
}
function closeMenu(e){
    let mobile_burger = document.querySelector('.mobile_burger')
    mobile_burger.classList.remove('open')
}
function handleClick(e){
    e.stopPropagation();
}
